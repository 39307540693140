export const checkPermission = (props) => {
  const {
    permId,
    permissions,
    view,
    modules = [],
    isCustomReport = false,
    reportId,
    customReports = []
  } = props;
  if (view?.toLowerCase() === 'admin') {
    return true;
  } else {
    let permission = permissions?.find(
      (permission) => permission.id === permId
    );
    if (permission != null) {
      const module = modules.find(
        (module) => module.id === permission.moduleId
      );
      const hasAccess = module != null && module.hasClientSubscribed;
      let customReport;
      if (isCustomReport)
        customReport = customReports.find((report) => report.rid === reportId);
      return isCustomReport ? customReport != null : hasAccess;
    } else return false;
  }
};

export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const getListFromConstant = (commonConstant) => {
  const list = [];
  for (const key in commonConstant) {
    list.push(commonConstant[key]);
  }
  return list;
};

export const getInitials = (name) => {
  if (name === 'admin') {
    let first_name = name.toUpperCase().charAt(0);
    return first_name;
  } else {
    if (name) {
      let first_name = name.charAt(0).toUpperCase();
      let last_name = name.split(' ').pop().charAt(0).toUpperCase();
      return first_name + last_name;
    }
  }
};
export const getBackgroundColorBasedOnCondition = (legalName) => {
  const firstLetter = legalName ? legalName.trim().toUpperCase().charAt(0) : '';
  // Assign colors based on the first letter
  if (firstLetter >= 'A' && firstLetter <= 'E') {
    return '#008080';
  } else if (firstLetter >= 'F' && firstLetter <= 'J') {
    return '#008000';
  } else if (firstLetter >= 'K' && firstLetter <= 'O') {
    return '#800000';
  } else if (firstLetter >= 'P' && firstLetter <= 'T') {
    return '#4c4cba';
  } else if (firstLetter >= 'U' && firstLetter <= 'Z') {
    return '#808000';
  } else {
    return '#ccc'; // Default color for other cases
  }
};
export const getDateDifference = (startDate, endDate) => {
  // Convert the dates to milliseconds
  const startMillis = new Date(startDate);
  const endMillis = new Date(endDate);
  startMillis.setUTCHours(0, 0, 0, 0);
  endMillis.setUTCHours(0, 0, 0, 0);
  // Calculate the difference in milliseconds
  const differenceMillis =
    startMillis.getTime() > endMillis.getTime()
      ? startMillis.getTime() - endMillis.getTime()
      : endMillis.getTime() - startMillis.getTime();

  // Convert milliseconds to days
  const days = Math.floor(differenceMillis / (1000 * 60 * 60 * 24));
  return days;
};

export const addISTDiffTime = (date) => {
  date.setTime(date.getTime() + (date.getTimezoneOffset() + 330) * 60 * 1000);
};

export const removeISTDiffTime = (date) => {
  date.setTime(date.getTime() - (date.getTimezoneOffset() + 330) * 60 * 1000);
};
export const removeTimeFromDate = (date) => {
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
};
export const getMonthsDifference = (startDate, endDate) => {
  // Convert the dates to UTC to avoid issues with daylight saving time
  const start = new Date(
    Date.UTC(startDate.getFullYear(), startDate.getMonth(), 1)
  );
  const end = new Date(Date.UTC(endDate.getFullYear(), endDate.getMonth(), 1));
  // Calculate the difference in months
  const yearsDifference = end.getFullYear() - start.getFullYear();
  const monthsDifference = end.getMonth() - start.getMonth();
  // Total months difference
  const totalMonthsDifference = yearsDifference * 12 + monthsDifference;
  return totalMonthsDifference;
};

export const base64ToBlob = (base64, mimeType) => {
  const byteCharacters = atob(base64.split(',')[1]); // Remove base64 format prefix (`data:mimeType;base64,`) if present
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: mimeType });
};

export const downloadBlobFile = (blob, fileName) => {
  const blobUrl = URL.createObjectURL(blob);

  // Create a link element
  const link = document.createElement('a');
  link.href = blobUrl;
  link.download = fileName;

  // Append link to the body
  document.body.appendChild(link);

  // Force download
  link.click();

  // Clean up and remove the link
  link.parentNode.removeChild(link);
  setTimeout(() => {
    window.URL.revokeObjectURL(blobUrl);
  }, 100);
};

export const downloadBase64File = (base64, mimeType, fileName) => {
  const blob = base64ToBlob(base64, mimeType);
  downloadBlobFile(blob, fileName);
};

export const openBlobInNewTab = (blob) => {
  const blobUrl = URL.createObjectURL(blob);
  window.open(blobUrl, '_blank');
};

export const openBase64InNewTab = (base64, mimeType) => {
  const blob = base64ToBlob(base64, mimeType);
  openBlobInNewTab(blob);
};
//  convert string value to Boolean
export const convertStringToBoolean = (value) => {
  if (['TRUE', 'True', 'true', true].includes(value)) {
    return true;
  } else if (['FALSE', 'False', 'false', false].includes(value)) {
    return false;
  }
};

export const getMonthName = (monthNumber) => {
  const monthMapping = {
    1: 'January',
    2: 'February',
    3: 'March',
    4: 'April',
    5: 'May',
    6: 'June',
    7: 'July',
    8: 'August',
    9: 'September',
    10: 'October',
    11: 'November',
    12: 'December'
  };
  return monthMapping[monthNumber] || ''; // Return the month name or an empty string if the number is invalid
};
